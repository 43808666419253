<template>
    <div>
        <strong>Curb Training Document Upload</strong>
        <br>
        <div class="form-group">
            <label>File title</label>
            <input type="text" v-model="state.fileName" class="form-control" />
            <span><em>(The curb type will automatically be prepended to the file title)</em></span>
        </div>
        <div class="form-group">
            <label>Curb Type</label>
            <select v-model="state.referenceID" class="form-control">
                <option v-for="[id, type] in cache.curbTypeCache.CURB_TYPES" v-bind:key="id" :value="id">
                    {{ type }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import curbTypeCache from "@/cache/curbType.cache";

    export default {
        name: "CurbTrainingDocumentFileHandler",
        data() {
            return {
                state: fileUploadStore.state,
                cache: {
                    curbTypeCache
                },
                fileData: {
                    fileTypeID: this.$cdiVars.FILE_TYPE_CURB_TRAINING_DOCUMENT,
                    referenceID: curbTypeCache.ADAPTER
                }
            }
        },
        created() {
            fileUploadStore.initialize(this.fileData);
        }
    }
</script>

<style scoped>

</style>